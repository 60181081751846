.appointment-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, sans-serif;
  }
  
  .appointment-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 24px;
  }
  
  .appointment-details {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
  
  .detail-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: #666;
  }
  
  .detail-row.cancelled {
    text-decoration: line-through;
    color: #999;
  }
  
  .detail-row svg {
    margin-right: 12px;
    color: #666;
  }
  
  .calendar-container {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .calendar-header h2 {
    font-size: 20px;
    font-weight: 500;
  }
  
  .calendar-nav {
    display: flex;
    gap: 16px;
  }
  
  .calendar-nav button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #666;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0.5rem;
}
  
  .calendar-day-header {
    text-align: center;
    font-size: 14px;
    color: #666;
    padding: 8px;
  }
  
  .calendar-day {
    aspect-ratio: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    padding: 0 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .calendar-grid {
      gap: 0.25rem;
  }

  .calendar-day {
      font-size: 0.75rem;
  }
}
  
  .calendar-day.selected {
    background-color: #0066ff;
    color: white;
  }
  
  .calendar-day.today {
    font-weight: bold;
  }
  
  .calendar-day.disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .calendar-day.other-month {
    color: #999;
  }
  
  .calendar-day:hover:not(.disabled):not(.selected) {
    background-color: #f0f0f0;
  }
  
  /* Time slots grid responsiveness */
.time-slots {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 0.5rem;
}

@media (max-width: 576px) {
  .time-slots {
      grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }
}

  
  .time-slot {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background: white;
    transition: all 0.2s;
  }
  
  .time-slot:hover:not(.disabled) {
    background-color: #f5f5f5;
    border-color: #0066ff;
  }
  
  .time-slot.selected {
    background-color: #0066ff;
    color: white;
    border-color: #0066ff;
  }
  
  .time-slot.disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
  }
  
  .zoom-link {
    word-break: break-all;
    color: #0066ff;
    text-decoration: none;
  }
  
  .action-button {
    background-color: #0066ff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-top: 24px;
  }
  
  .action-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .timezone-select {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    margin-bottom: 24px;
    font-size: 16px;
  }

  .contact-form {
    margin-top: 24px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
}

.form-group {
    margin-bottom: 16px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #666;
    font-size: 14px;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
}

.form-group input:focus {
    outline: none;
    border-color: #0066ff;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
}

.loading-message,
.error-message,
.no-slots-message {
    text-align: center;
    padding: 20px;
    color: #666;
}

.error-message {
    color: #dc3545;
}

.time-slot svg {
    margin-right: 8px;
    vertical-align: middle;
}

/* Form improvements */
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.15);
}

/* Card enhancements */
.card {
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/* Button improvements */
.btn-outline-secondary:hover:not(:disabled) {
  background-color: #f8f9fa;
}

.btn-primary:disabled {
  opacity: 0.65;
}

/* Loading state */
.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

/* Alert styling */
.alert {
  border-radius: 0.5rem;
}

/* Additional responsive styles */
@media (max-width: 768px) {
    .calendar-container {
        padding: 16px;
    }

    .calendar-day {
        font-size: 14px;
    }

    .time-slots {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }

    .time-slot {
        padding: 8px;
        font-size: 14px;
    }
}