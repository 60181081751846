.wave-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(238, 242, 255, 0.9) 0%, rgba(248, 250, 252, 0.9) 100%);
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.wave1 {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 800 88.7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23818cf8' fill-opacity='0.1'/%3E%3C/svg%3E");
  background-size: 50% 100px;
  animation: wave 18s linear infinite;
  z-index: 1;
}

.wave2 {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 800 88.7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%236366f1' fill-opacity='0.1'/%3E%3C/svg%3E");
  background-size: 50% 120px;
  animation: wave 12s linear infinite;
  z-index: 2;
}

.wave3 {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 800 88.7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%234f46e5' fill-opacity='0.1'/%3E%3C/svg%3E");
  background-size: 50% 90px;
  animation: wave 15s linear infinite;
  z-index: 3;
}

@keyframes wave {
  0% { transform: translateX(0) translateZ(0) scaleY(1); }
  50% { transform: translateX(-25%) translateZ(0) scaleY(0.95); }
  100% { transform: translateX(-50%) translateZ(0) scaleY(1); }
}